<template>
  <div @click="onClick" class="a-card-subtitles">
    <div class="subtitle-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="17"
        viewBox="0 0 21 17"
        fill="none"
      >
        <path
          d="M0.197858 2.48528C0.211805 1.22464 1.24808 0.211046 2.50872 0.224993L18.5077 0.401997C19.7684 0.415944 20.782 1.45222 20.768 2.71286L20.6416 14.1407C20.6276 15.4014 19.5914 16.415 18.3307 16.401L2.33172 16.224C1.07108 16.2101 0.0574802 15.1738 0.0714271 13.9132L0.197858 2.48528ZM7.29603 6.56406C7.80314 6.56967 8.25784 6.79257 8.5682 7.14959C8.87855 7.50662 9.42106 7.5412 9.77809 7.23084C10.1351 6.92049 10.1697 6.37798 9.85933 6.02095C9.24212 5.31408 8.33279 4.86114 7.31856 4.84991C5.42582 4.82897 3.87321 6.34761 3.85227 8.24035C3.83133 10.1331 5.34996 11.6857 7.2427 11.7066C8.25693 11.7179 9.17606 11.2851 9.80876 10.5921C10.1269 10.242 10.1043 9.70246 9.7543 9.38071C9.40427 9.05896 8.86466 9.08514 8.54291 9.43517C8.22473 9.78524 7.76521 9.99803 7.2581 9.99242C6.31173 9.98195 5.55241 9.20564 5.56288 8.25927C5.57335 7.3129 6.34966 6.55359 7.29603 6.56406ZM12.4196 8.33513C12.4301 7.38876 13.2064 6.62945 14.1527 6.63992C14.6599 6.64553 15.1146 6.86843 15.4249 7.22545C15.7353 7.58248 16.2778 7.61705 16.6348 7.3067C16.9918 6.99634 17.0264 6.45384 16.7161 6.09681C16.0988 5.38994 15.1895 4.93699 14.1753 4.92577C12.2825 4.90483 10.7299 6.42346 10.709 8.31621C10.6881 10.2089 12.2067 11.7616 14.0994 11.7825C15.1137 11.7937 16.0328 11.361 16.6655 10.668C16.9837 10.3179 16.9611 9.77832 16.611 9.45657C16.261 9.13482 15.7214 9.16099 15.3996 9.51103C15.0815 9.8611 14.6219 10.0739 14.1148 10.0683C13.1684 10.0578 12.4091 9.2815 12.4196 8.33513Z"
          fill="#1B57F7"
        />
      </svg>
      <div
        class="flag-wrapper"
        :v-if="languages"
        v-for="language in languages"
        :key="language.id"
      >
        <SubtitlesFlag :image="language.attributes.flag" />
      </div>
    </div>
    <SubtitlesModal ref="subtitle-modal" v-if="popupEnabled" />
  </div>
</template>

<script>
export default {
  components: {
    SubtitlesFlag: () => import("/components/Subtitles/SubtitlesFlag.vue"),
    SubtitlesModal: () => import("/components/Subtitles/SubtitlesModal.vue"),
  },
  props: {
    languages: {
      type: Array,
      required: true,
    },
    popupEnabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick() {
      if (!this.popupEnabled) {
        return;
      }
      this.$refs["subtitle-modal"].showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.a-card-subtitles {
  cursor: pointer;
}

.subtitle-content {
  background-color: var(--a-color-blue-subtitles);
  width: fit-content;
  display: flex;
  align-items: center;
  height: 2.0625rem;
  gap: 0.5rem;
  padding: 0 0.6875rem;
  border-radius: 0.25rem;

  .flag-wrapper {
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
